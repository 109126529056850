import React, { memo } from "react";
import { Center } from "@chakra-ui/react";

const Loading = () => {
  return (
    <Center h='100vh' w='100vw'>
      Loading...
    </Center>

  )
}

export default memo(Loading);